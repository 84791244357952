import React, { useState, useEffect } from 'react';
import HeatMeter from './HeatMeter';
import LineChart from './LineChart';
import BarChart from './BarChart';
import RatingForm from './RatingForm';
import RatingFeed from './RatingFeed';
import useWebSocket from '../hooks/useWebSocket';
import { submitRating, getRatingHistory } from '../utils/api';

function App() {
  const [averageHeat, setAverageHeat] = useState(0);
  const [allRatings, setAllRatings] = useState([]);
  const [error, setError] = useState('');

  const { lastMessage } = useWebSocket();

  useEffect(() => {
    if (lastMessage) {
      try {
        const data = JSON.parse(lastMessage);
        setAverageHeat(data.averageHeat);
        setAllRatings(data.allRatings);
      } catch (err) {
        console.error('Error parsing WebSocket message:', err);
      }
    }
  }, [lastMessage]);

  const handleSubmit = async (rating, comment) => {
    try {
      await submitRating(rating, comment);
      setError('');
    } catch (err) {
      setError('Error submitting rating. Please try again.');
    }
  };

  const handleViewHistory = async (userId) => {
    try {
      const history = await getRatingHistory(userId);
      console.log(history);
    } catch (err) {
      setError('Error fetching rating history.');
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-4xl font-bold mb-8 text-center">Heat Rating Website</h1>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div>
          <HeatMeter value={averageHeat} />
          <LineChart data={[]} />
          <BarChart data={[]} title="Daily Average" />
          <BarChart data={[]} title="Weekly Average" />
        </div>

        <div>
          <RatingForm onSubmit={handleSubmit} />
          {error && <div className="text-red-500 mt-2">{error}</div>}
          <RatingFeed ratings={allRatings} onViewHistory={handleViewHistory} />
        </div>
      </div>
    </div>
  );
}

export default App;