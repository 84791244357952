import { useState, useEffect } from 'react';

const useWebSocket = () => {
  const [socket, setSocket] = useState(null);
  const [lastMessage, setLastMessage] = useState(null);

  useEffect(() => {
    const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
    const host = window.location.hostname;
    const ws = new WebSocket(`${protocol}//${host}`);

    ws.onmessage = (event) => {
      setLastMessage(event.data);
    };

    setSocket(ws);

    return () => {
      ws.close();
    };
  }, []);

  return { lastMessage, socket };
};

export default useWebSocket;