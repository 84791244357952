import axios from 'axios';

const API_URL = ''; // Empty string for same-origin requests

export const submitRating = async (rating, comment) => {
  const response = await axios.post(`${API_URL}/api/submit-rating`, { rating, comment });
  return response.data;
};

export const getRatingHistory = async (userId) => {
  const response = await axios.get(`${API_URL}/api/rating-history/${userId}`);
  return response.data;
};